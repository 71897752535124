import React, { useEffect, useRef, useState } from "react";
import { TweenMax } from "gsap";
import "./Cursor.scss";
import { useSelector } from "react-redux";

function Cursor() {
  const cursor = useRef(null);
  const dot = useRef(null);
  const [tapped, setTapped] = useState(false);
  const hoverNode = useSelector(state => state.hoverNode);
  const cursorLock = useSelector(state => state.cursorLock);


  useEffect(() => {
    function move(e) {
      let x = e.clientX;
      let y = e.clientY;

      if(cursorLock && hoverNode) {
        const elBounds = hoverNode.getBoundingClientRect();
        x = elBounds.x + elBounds.width/2;
        y = elBounds.y + elBounds.height/2;
      }

      TweenMax.to(cursor.current, 0.5, { x, y });
    }

    function tap() {
      setTapped(true);
    }

    document.addEventListener("click", tap);
    document.addEventListener("mousemove", move);
    
    return () => {
      document.removeEventListener("click", tap);
      document.removeEventListener("mousemove", move);
    }
  }, [cursorLock, hoverNode]);

  useEffect(() => {

    TweenMax.to(cursor.current, 0.3, {
      scale: hoverNode ? (tapped ? 1.5 : 1.8) : (tapped ? 1.5 : 1),
      opacity: hoverNode ? (tapped ? 0.8 : 0.6) : (tapped ? 0.8 : 1),
      onComplete: function () {
        setTapped(false);
      },
    });

    TweenMax.to(dot.current, 0.3, {
      scale: hoverNode ? 1 : .2,
    });
  }, [tapped, hoverNode]);


  

  return (
    <div className={`Cursor ${hoverNode ? 'scaled' : ''}`} ref={cursor}>
      <div className="dot" ref={dot}></div>
    </div>
  );
}

export default Cursor;
