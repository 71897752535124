import React, { useRef } from "react";
import { useSelector } from "react-redux";


function SideText() {
  const sideTextEl = useRef(null);
  const sideText = useSelector((state) => state.sideText);

  return (
    <>
      <p id="side-text" ref={sideTextEl} dangerouslySetInnerHTML={{ __html: sideText }}></p>
      <p id="p-side-text" ref={sideTextEl} dangerouslySetInnerHTML={{ __html: sideText }}></p>
    </>
  )
}

export default SideText;