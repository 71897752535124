export const EXPAND = "EXPAND";
export const HOVER = "HOVER";
export const BUTTON_HIGHLIGHT = "BUTTON_HIGHLIGHT";
export const SET_BUTTON_TYPE = "SET_BUTTON_TYPE";
export const SET_SIDE_TEXT = "SET_SIDE_TEXT";
export const SET_SIDE_HEADING = "SET_SIDE_HEADING";
export const UPDATE_SIDE_TEXT = "UPDATE_SIDE_TEXT";
export const UPDATE_SIDE_HEADING = "UPDATE_SIDE_HEADING";
export const IMAGE_COLLAPSE = "IMAGE_COLLAPSE";
export const CLICK_PROJECT = "CLICK_PROJECT";
export const ACTIVE_PROJECT = "ACTIVE_PROJECT";