import React from "react";
import Clickable from "../Clickable/Clickable";

function Contact() {
  
  const contactMe = () => {
    let a = document.createElement('a');
    a.target = '_blank';
    a.href = 'mailto:naumansamejo@gmail.com';
    a.click();
  }

  return (
    <div className="content-block about-block">
      <h2>#contact-me</h2>
      <p>
        Got an awesome project to discuss? or just wanna say hi?<br/>
        Shoot me a message at:<br/>
      </p>

      <Clickable>
        <button className="link" onClick={contactMe}>
            <span className="val">
              naumansamejo@gmail.com
            </span>
            <span className="bar"></span>
          </button>
      </Clickable>

    </div>
  )
}

export default Contact;