import React, { useEffect, useRef } from "react";
import { TweenMax, TimelineMax } from "gsap";
import Clickable from "../Clickable/Clickable";
import { useSelector, useDispatch } from "react-redux";
import { setClickedProject, highlightButton, setButtonType, expandContent, setSideHeading, setSideText, setImageCollapse } from "../../store/actions";

function MainButton () {
  const buttonBar = useRef(null);
  const buttonHighlight = useSelector((state) => state.buttonHighlight);
  const buttonType = useSelector((state) => state.buttonType);
  const dispatch = useDispatch();

  useEffect(() => {
    if (buttonHighlight) {
      let repeat = 2;
      let hireTl = new TimelineMax({ repeat: repeat });
      let count = 0;
      let defaultColor = buttonBar.current.style.backgroundColor;

      hireTl
        .set(buttonBar.current, {
          backgroundColor: "#689f38",
          opacity: 0.6,
        })
        .fromTo(
          buttonBar.current,
          0.7,
          {
            x: "-100%",
          },
          {
            x: "100%",
            onComplete: function () {
              if (count++ === repeat) {
                TweenMax.to(buttonBar.current, 0.5, {
                  x: "0%",
                  backgroundColor: defaultColor,
                  opacity: 0.32,
                });
              }
            },
          }
        );
    }
  }, [buttonHighlight]);

  function buttonClick(type) {
    const knowMore = () => {
      dispatch(expandContent());
      
      setTimeout(() => {
        
        dispatch(highlightButton());
        dispatch(setButtonType("HIRE_ME"));
        dispatch(setSideText("Available for freelance hire!"));

      }, 1000);
    };
    
    const goBack = () => {

      dispatch(setImageCollapse(false));
      dispatch(setSideText("Available for freelance hire!"));
      dispatch(setSideHeading());

      dispatch(setButtonType("HIRE_ME"));

      dispatch(setClickedProject(null));
    };

    const hireMe = () => {
      let a = document.createElement('a');
      a.target = '_blank';
      a.href = 'mailto:naumansamejo@gmail.com';
      a.click();
    }

    switch (type) {
      case "KNOW_MORE":
        knowMore();
        break;
      case "GO_BACK":
        goBack();
        break;
      case "HIRE_ME":
        hireMe();  
        break;
      default:
    }
  }

  const ButtonVal = () => {
    let buttonText;

    switch (buttonType) {
      case "KNOW_MORE":
        buttonText = "know more >";
        break;

      case "GO_BACK":
        buttonText = "< go back";
        break;

      case "HIRE_ME":
      default:
        buttonText = "hire me!";
    }

    return <span className="val">{buttonText}</span>;
  };

  return (
    <Clickable>
      <button
        className="link"
        onClick={() => {
          buttonClick(buttonType);
        }}
      >
        <ButtonVal />
        <span ref={buttonBar} className="bar"></span>
      </button>
    </Clickable>
  )
}

export default MainButton;