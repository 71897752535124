import React from "react";
import SideContent from "../SideContent/SideContent";
import CenterContent from "../CenterContent/CenterContent";
import "./Main.scss";

function Main() {
  return (
    <div className="Main" >
      <SideContent />
      <CenterContent />
    </div>
  );
}

export default Main;