import React, { useEffect } from "react";
import "./App.scss";
import Cursor from "./components/Cursor/Cursor";
import Main from "./components/Main/Main";

function App() {
  useEffect(() => {
    document.title = "Nauman Samejo | a Web Developer"
  }, []);
  return (
    <div className="App">
      <Main />
      <Cursor />
    </div>
  );
}

export default App;
