import React, { useRef, useEffect } from "react";
import { TweenMax, gsap, Power2 } from "gsap";
import Clickable from "../Clickable/Clickable";
import { useDispatch, useSelector } from "react-redux";
import { setSideText, setSideHeading, setButtonType, setImageCollapse, setActiveProject, setClickedProject } from "../../store/actions";

function Project({ image, title, description, children }) {
  const el = useRef(null);
  const activeProject = useSelector(state => state.activeProject);
  const clickedProject = useSelector(state => state.clickedProject);
  const dispatch = useDispatch();


  useEffect(() => {

    if(el.current === activeProject){
      // If this project is being hovered
      
      TweenMax.set(el.current.parentElement, {
        zIndex: 19,
      });

      TweenMax.to(el.current, 0.5, {
        height: function () {
          return el.current.querySelector("img").offsetHeight;
        },
        y: () =>
        (el.current.parentElement.offsetHeight -
          el.current.querySelector("img").offsetHeight) /
          2,
        ease: Power2.easeOut,
        opacity: 1,
        scale: 1
      });
    } else {
      // Applying effect on others + default

      if(gsap.getProperty(el.current.parentElement, "zIndex")===19) {
        TweenMax.set(el.current.parentElement, {
          zIndex: 14,
        });   
      }
    
      TweenMax.to(el.current, 0.5, {
        opacity: activeProject ? .15 : 1,
        scale: activeProject ? .96 : 1,
        height: 150,
        y: 0,
        onComplete: () => {
          if(gsap.getProperty(el.current.parentElement, "zIndex")===14) {
            TweenMax.set(el.current.parentElement, {
              zIndex: 11,
            });   
          }
        }
      });
    }

  }, [activeProject, el]);


  function onEnter() {
    if(clickedProject) return;

    dispatch(setActiveProject(el.current));
  }

  function onLeave() {
    if(clickedProject) return;
    
    dispatch(setActiveProject(null));
  }

  function onClick() {
    
    dispatch(setClickedProject(el.current));

    dispatch(setImageCollapse(true));
    dispatch(setSideText(description));
    dispatch(setSideHeading(
        `<span class='light' >Project: </span><br />
        <span style="font-size: 1.4em" >${title}</span>`
      ));
    dispatch(setButtonType("GO_BACK"));
  }

  return (
    <div className="portfolio-item-main">
        
        <div
          ref={el}
          className="portfolio-item clickable no-cursor-lock"
          >
          
          <div
            className="item-inner"
            onMouseEnter={onEnter}
            onMouseLeave={onLeave}
            onClick={onClick}
          >
            <Clickable cursorLock={false} >
              <div className="img">
                <img src={image} alt="" />
              </div>
            </Clickable>
          </div>

          <div className="portfolio-content" tabIndex="-1">
            {children}
          </div>

        </div>

      </div>
  );
}

export default Project;
