import { put, takeLatest, all } from "redux-saga/effects";
import { TweenMax } from "gsap";
import * as types from "./constants/ActionTypes";

function* setSideText({type, sideText }) {
  
  let el = "#side-text";
  let pEl = document.querySelector("#p-side-text");
  let actionType = types.SET_SIDE_TEXT;

  if(type === types.UPDATE_SIDE_HEADING) {
    el = "#side-heading";
    pEl = document.querySelector("#p-side-heading");
    actionType = types.SET_SIDE_HEADING;
  }

  const animate = () => (new Promise((res) => {
    pEl.innerHTML = sideText;

    TweenMax.to(el, 0.5, {
      autoAlpha: 0,
      height: pEl.offsetHeight,
      onComplete: res,
    });
  }));

  yield animate();
  yield put({ type: actionType, sideText });
  yield new Promise((res) =>
    TweenMax.to(el, 0.5, {
      delay: 0.1,
      autoAlpha: 1,
      onComplete: res,
    })
  );
}

function* sideTextWatcher() {
  yield takeLatest(types.UPDATE_SIDE_TEXT, setSideText);
}
function* sideHeadingWatcher() {
  yield takeLatest(types.UPDATE_SIDE_HEADING, setSideText);
}


export default function* rootSaga() {
  yield all([
    sideTextWatcher(),
    sideHeadingWatcher(),
  ]);
}
