import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { setHoverNode } from "../../store/actions";

function Clickable({ children, cursorLock = true }) {
  const dispatch = useDispatch();
  const childElement = React.Children.only(children);
  const node = useRef(null);

  function onMouseEnter() {
    dispatch(setHoverNode(node.current, cursorLock));
  }
  
  function onMouseLeave() {
    dispatch(setHoverNode(null, false));
  }
  
  return (
    React.cloneElement(
      childElement, 
      {
        ref: node,
        onMouseEnter,
        onMouseLeave
      }
    )
  )

}

export default Clickable;
