import React, { useEffect, useRef } from "react";
import pic from "../../media/pic.png";
import { useSelector } from "react-redux";
import { TweenMax } from "gsap";

function Picture() {
  const expanded = useSelector((state) => state.expanded);
  const imageCollapse = useSelector((state) => state.imageCollapse);
  const image = useRef(null);

  useEffect(() => {
    TweenMax.to(image.current, .5, {
      autoAlpha: imageCollapse ? 0 : 1
    })
  }, [imageCollapse]);

  return (
    <div ref={image} className="pic" style={expanded ? { filter: "none" } : null}>
      <img src={pic} alt="" />
    </div>
  );
}

export default Picture;
