import React, { useEffect, useRef } from "react";
import Project from "./Project";

import img1 from "../../media/p1.png";
import img3 from "../../media/p3.png";
import img5 from "../../media/p5.png";
import vid_wml from "../../media/WML_720.mov";
import vid_exd from "../../media/EXD_720.mov";
import vid_portfolio from "../../media/portfolio_720.mov";

import { useSelector, useDispatch } from "react-redux";
import { TweenMax } from "gsap";
import { setActiveProject, setClickedProject } from '../../store/actions';

function Projects() {
  const dispatch = useDispatch();
  const clickedProject = useSelector((state) => state.clickedProject);
  const activeProject = useSelector((state) => state.activeProject);

  useEffect(() => {
    const placeholderEl = document.querySelector("#project-p");

    if (clickedProject) {
      const { x, y, width, height } = clickedProject.getBoundingClientRect();
      TweenMax.set(placeholderEl, {
        x,
        y,
        width,
        height,
        innerHTML: clickedProject.innerHTML,
        autoAlpha: 1,
      });
      
      TweenMax.to("#center-content > .inner", 0.5, {
        autoAlpha: 0,
        onComplete: () => {

          const { x, y, width, height } = document
            .querySelector("#center-content")
            .getBoundingClientRect();
          
          TweenMax.to(placeholderEl, 0.5, {
            y,
            height,
            x,
            width,
            innerHTML: clickedProject.innerHTML,
            autoAlpha: 1
          });
          TweenMax.to(placeholderEl.querySelector(".img"), 0.5, {
            height: 0,
            delay: .2,
          });
          TweenMax.to(placeholderEl.querySelector("img"), 0.5, {
            autoAlpha: .4,
            delay: .2,
            onComplete: () => {
              placeholderEl.querySelector(".portfolio-content").focus();
            }
          });

        },
      });

    } else if(activeProject) {

      const { x, y, width, height } = activeProject
            .getBoundingClientRect();

      TweenMax.to(placeholderEl.querySelector(".img"), 0.5, {
        height: "auto",
      });
      TweenMax.to(placeholderEl.querySelector("img"), 0.5, {
        autoAlpha: 1,
        onComplete: () => {
        }
      });

      TweenMax.to(placeholderEl, .5, {
        x,
        y,
        width,
        height,
        // autoAlpha: 0,

        onComplete: () => {
          
          TweenMax.to("#center-content > .inner", 0.5, {
            autoAlpha: 1,
            onComplete: () => {
              TweenMax.set(placeholderEl, {autoAlpha: 0});
              // setTimeout(() => {
                dispatch(setActiveProject(null));
              // }, 100);
            },
          });
          
        }
      });

    }

  }, [clickedProject, dispatch]);

  return (
    <div className="content-block portfolio-block">
      <h2>#portfolio</h2>

      <Project
        image={img1}
        title="WML"
        description={
          `<strong>Skills:</strong>
          HTML/CSS, Javascript, GSAP, LottieJS
          <br>
          <strong>Turnaround:</strong> ~3 weeks<br>`
        }
      >
        <h2>
          WML, <br />
          Limburg Water Experience
        </h2>
        <div className="portfolio-video" >
          <i>website preview</i>
          <video autoPlay loop>
            <source src={vid_wml} type="video/mp4" />
          </video>
        </div>
        
        <p>
          I've always been fascinated by highly interactive websites, like the ones at awwwards.com; and I felt incredibily lucky when I got a chance to work on an interactive website of that standard.
        </p>

        <p>
          The 70% of design, and a simple video demonstratiing the concept was already prepared by the designer when I got onto this project.
        </p>
        
        <p>
          The requirements were to build pixel-perfect website from the given design, and all the animations and interactions as given in the sample video, and the new ones as the need may arise. The ability to update content whenever the client needed was also needed, some sort of custom CMS was enough.
        </p>
        
        <p>
          The site was already animation-heavy, having animations on every view of the page, going up to 18 individual animations playing at one screen, of them some were simple, and some very complex animatings objects. The site had to be built in a way to minimize the use of GPU memory as much as possible to provide better performance to the users.
          <br />
          So, as a solution to that problem, I wrote a function, which efficiently managed the animations to improve performance.
        </p>

        <p>
          I used the best programming practices as much as I could at the time, taking care of causing fewer and fewer bugs and making it relatively easy to extend or modify the website, retaining control over each part of the site, completely.
        </p>

        <p>
          Programming-wise, the site was split into multiple views, glass, intro and summary views. The summary page was split into chapters (waterkringloop, waterwinning, etc). Each chapter had multiple slides, with every one of them containing "initial slide" containing bigger version of icon where the chapter's icon from summary transitioned to. Each chapter's slides must be navigatable through: left-right arrow keys, by dragging, by clicking/tapping on either side and by the rotating navigation at the top of the chapter. So, programmatiacally, the chapters were represented as objects, which each one having methods to control the navigation of their slides, their current state and all the getters/setters that might be required by other components, such as the spinning navigation, the bottom menu (which is used to switch chapters), etc. Each chapter contained it's own timeline object, so to retain control over transition and animations of each slide.
        </p>

        <p>
        <strong>In short: </strong>The architecture of site was optimised for performance, reusability, maintanance and modifications. And as a result, we got a great website.
        </p>

      </Project>

      
      <Project
        image={img3}
        title="E XD"
        description={
          `<strong>Skills:</strong>
          HTML/CSS, Javascript, Skrollr, SVG animations
          <br>
          <strong>Turnaround:</strong> ~1.5 weeks<br>`
        }
      >
        <h2>
          E XD, <br />
          Parallax Landing Page
        </h2>

        <div className="portfolio-video" >
          <i>website preview</i>
          <video autoPlay loop>
            <source src={vid_exd} type="video/mp4" />
          </video>
        </div>

        <p>
          In this project, I was given a PSD design, 2x scaled. Had to convert that to static html/css website, pixel-perfectly.
          The site had to be parallax, creative and responsive.
        </p>

        <p>
          For the development, I custom-coded it (due to performance, SEO), and chose the library Skrollr for scrolling animations and parallax effect.
        </p>
      </Project>

      <Project
        image={img5}
        title="<span style='font-size: .75em;' >
        Portfolio
        </span>"
        description={
          `<strong>Skills:</strong>
          HTML/CSS, Javascript, React, Redux, GSAP
          <br>
          <strong>Turnaround:</strong> ~1.5 weeks
          <br>
          <strong>Code:</strong> <a href='https://github.com/naumansamejo/personal-site' target='_blank' >github &#x2197;</a>
          `
        }
      >
        <h2>
         Portfolio, <br/>
         Personal Website
        </h2>

        <div className="portfolio-video" >
          <i>website preview</i>
          <video autoPlay loop>
            <source src={vid_portfolio} type="video/mp4" />
          </video>
        </div>

        <p>
          The goal of this project was to build my personal website, where I can keep my portfolio, achivements, all in one place. The site had to be friendly, fully-custom, matches my personality and shows my skills, all while keeping it simple. As I work as a freelancer, I had to find a place to convinently display my freelance availability and a way for potential clients to contact me.
        </p>

        <p>
          The site is completely designed &amp; developed by me from scratch. I wanted to have super-smooth animations and interactions on the website, so for that, I used GSAP for my animations &amp; transitions. <br />
          I tried keeping it simple, and as custom as possible, for that, I used a monochrome colorscheme (which I kind of like as it represents me), and to make the site look fully custom, I developed a custom friendly cursor for the website, which snapped and changed its shape over clickable elements, providing visual cue to the users of what was clickable and what was not, while making the whole site look custom.
        </p>

        <p>
          The code is available at <a href="https://github.com/naumansamejo/personal-site" target="_blank" >github  &#x2197;</a>. Free to modify and reuse (except images, media and assets).
          This repo serves as an example of my coding-style.
        </p>


      </Project>
        
      <p>...and there are a lot more!</p>
      
    </div>
  );
}

export default Projects;
