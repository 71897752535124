
import * as types from '../../constants/ActionTypes'

const SIDE_HEADING =
`Nauman <br />
<span style="font-size: 1.4em" >Samejo</span>`;

const SIDE_TEXT = 
`A Human, from Earth. <br />
A full stack web developer. <br />
Knows some of UI/UX. <br />
<!-- People call me entrepreneur. <br />
Been 20 years in this world. <br /> -->
I work as a freelancer.`;

const initialState = {
  expanded: false,
  hoverNode: null,
  cursorLock: false,
  buttonHighlight: 0,
  sideText: SIDE_TEXT,
  sideHeading: SIDE_HEADING,
  buttonType: "KNOW_MORE",
  activeProject: null,
  imageCollapse: false,
  clickedProject: null,
}

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ACTIVE_PROJECT:
      return {...state, activeProject: action.activeProject}
    case types.CLICK_PROJECT:
      return {...state, clickedProject: action.clickedProject}
    case types.SET_SIDE_TEXT:
      return {...state, sideText: action.sideText || SIDE_TEXT}
    case types.IMAGE_COLLAPSE:
      return {...state, imageCollapse: action.collapse}
    case types.SET_SIDE_HEADING:
      return {...state, sideHeading: action.sideText || SIDE_HEADING}
    case types.SET_BUTTON_TYPE:
      return {...state, buttonType: action.buttonType}
    case types.BUTTON_HIGHLIGHT:
      return {...state, buttonHighlight: state.buttonHighlight+1}
    case types.EXPAND:
      return {...state, expanded: true};
    case types.HOVER:
      return {...state, hoverNode: action.node, cursorLock: action.cursorLock}
    default:
      return state
  }
}

export default rootReducer