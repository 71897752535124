import React from "react";

function About() {
  return (
    <div className="content-block about-block">
      <h2>#about-me</h2>
      <p>
        I'm a self-taught web developer, a problem solver, an author at envato market &amp; a freelancer with over 5 years of experience. <br/>
        I love coding and designing simple yet beautiful websites. <br />
        I'm a MERN and <a href="https://jamstack.org/" target="_blank" >JAM Stack</a> developer. <br/>
        I have a diverse set of skills, ranging from design to frontend to backend development. <br />
        If you are someone looking for a developer &amp; a designer, or got an idea to discuss, get in touch with me <a href="mailto:naumansamejo@gmail.com" target="_blank" >here</a>!
      </p>
    </div>
  )
}

export default About;