import * as types from "../../constants/ActionTypes";

export const setHoverNode = (node, cursorLock) => ({
  type: types.HOVER,
  node,
  cursorLock,
});

export const setSideText = (text) => ({
  type: types.UPDATE_SIDE_TEXT,
  sideText: text,
});

export const setSideHeading = (text) => ({
  type: types.SET_SIDE_HEADING,
  sideText: text,
});

export const setImageCollapse = (collapse) => ({
  type: types.IMAGE_COLLAPSE,
  collapse
});


export const highlightButton = () => ({
  type: types.BUTTON_HIGHLIGHT
});

export const setButtonType = (buttonType) => ({
  type: types.SET_BUTTON_TYPE,
  buttonType,
});

export const expandContent = () => ({
  type: types.EXPAND
});


export const setActiveProject = (activeProject) => ({
  type: types.ACTIVE_PROJECT, 
  activeProject
});

export const setClickedProject = (clickedProject) => ({
  type: "CLICK_PROJECT", 
  clickedProject
});