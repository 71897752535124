import React, { useEffect, useRef } from "react";
import { TweenMax } from "gsap";
import "./SideContent.scss";
import { useSelector } from "react-redux";
import MainButton from "./MainButton";
import SideText from "./SideText";
import Picture from "./Picture";

function SideContent() {
  const main = useRef(null);
  const expanded = useSelector((state) => state.expanded);
  const sideHeading = useSelector((state) => state.sideHeading);
  const imageCollapse = useSelector(state => state.imageCollapse);

  useEffect(() => {
    if (expanded) {
      TweenMax.to(main.current, 0.8, {
        x: 0,
      });
      return;
    }

    const centerOffset =
      (main.current.parentElement.offsetWidth - main.current.offsetWidth) / 2;

    TweenMax.set(main.current, {
      x: centerOffset,
    });
  }, [expanded]);


  useEffect(() => {
    
    TweenMax.to(main.current.children[0], .5, {
      y: imageCollapse ? -150 : 0
    });

  }, [imageCollapse]);

  return (
    <div className="SideContent" ref={main}>
      <div className="inner">
        <Picture />

        <h1 id="side-heading" dangerouslySetInnerHTML={{ __html: sideHeading }}></h1>
        <h1 id="p-side-heading" dangerouslySetInnerHTML={{ __html: sideHeading }}></h1>

        <SideText />
        <MainButton />
      </div>
    </div>
  );
}

export default SideContent;
