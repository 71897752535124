import React, { useEffect, useRef } from "react";
import "./CenterContent.scss";
import { useSelector } from "react-redux";
import { TweenMax } from "gsap";
import About from "../About/About";
import Contact from "../Contact/Contact";
import Projects from "../Projects/Projects"


function CenterContent() {
  const expanded = useSelector(state => state.expanded);
  const mainEl = useRef(null);

  useEffect(() => {
    TweenMax.to(mainEl.current, 1, {
      autoAlpha: expanded ? 1 : 0,
      delay: .5,
    });
  }, [expanded]);


  return (

    <div className="CenterContent" id="center-content" ref={mainEl}>
      <div className="inner">
        <About />
        <Projects />
        <Contact />
      </div>
      
      <div id="project-p" ></div>
    </div>
  );
}

export default CenterContent;